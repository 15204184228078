import R3sFetch from './R3sFetch'
import { handleFetchResponse, serialize } from './ApiHelpers'

export default {
  async get(userId) {
    const response = await R3sFetch(`/api/1.0/users/get?id=${userId || ''}`)
    return await handleFetchResponse(response)
  },

  async getUsersInPage() {
    const response = await R3sFetch(`/api/1.0/users/team/page/get`)
    return await handleFetchResponse(response)
  },

  async update(record) {
    const response = await R3sFetch(`/api/1.0/users/update`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ record }),
    })
    return await handleFetchResponse(response)
  },

  async create(
    email,
    firstname = null,
    lastname = null,
    title = null,
    type = null,
    role = null
  ) {
    const response = await R3sFetch(`/api/1.0/users/record/create`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        email,
        firstname,
        lastname,
        title,
        type,
        role,
      }),
    })
    return await handleFetchResponse(response)
  },

  async bulkCreate(users, type = null, role = null) {
    const response = await R3sFetch(`/api/1.0/users/bulk/create`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ users, type, role }),
    })
    return await handleFetchResponse(response)
  },

  async getPersonalizations(type = '') {
    const response = await R3sFetch(
      `/api/1.0/users/personalizations/get?type=${type}`
    )
    return await handleFetchResponse(response)
  },

  async updatePersonalization(type, parameters, replaceValue = false) {
    const response = await R3sFetch(`/api/1.0/users/personalizations/update`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ type, parameters, replaceValue }),
    })
    return await handleFetchResponse(response)
  },

  async getTeamModulePermissions() {
    const response = await R3sFetch(`/api/1.0/users/team/module/permissions`)
    return await handleFetchResponse(response)
  },

  async updateTeamModulePermissions(permissions, userId = null, teamId = null) {
    const response = await R3sFetch(
      `/api/1.0/users/team/module/permissions/update`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ permissions, userId, teamId }),
      }
    )
    return await handleFetchResponse(response)
  },

  async checkCanUserUpdateRoleAndUserType(targetUserId) {
    const response = await R3sFetch(
      `/api/1.0/users/permissions/change?id=${targetUserId}`
    )
    return await handleFetchResponse(response)
  },

  async getAvailableNavItems() {
    const response = await R3sFetch(`/api/1.0/users/nav`)
    return await handleFetchResponse(response)
  },

  async getUserPermissionCode() {
    const response = await R3sFetch(`/api/1.0/users/permission/code`)
    return await handleFetchResponse(response)
  },

  async getQueueSummary() {
    const response = await R3sFetch(`/api/1.0/users/queue/summary`)
    return await handleFetchResponse(response)
  },

  async getUsersInTeam(includeDisabled = false, page = 1, pageSize = 20) {
    const response = await R3sFetch(
      `/api/1.0/users/team/getall?disabled=${
        includeDisabled || ''
      }&page=${page}&pageSize=${pageSize}`
    )
    return await handleFetchResponse(response)
  },

  async bulkUpload(fileName) {
    const response = await R3sFetch(`/api/1.0/users/bulk/upload`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ filename: fileName }),
    })
    return await handleFetchResponse(response)
  },

  async getUserAlerts(
    page = 1,
    pageSize = 5,
    includeAck = false,
    search = null
  ) {
    let filters = { page, pageSize }
    if (typeof includeAck === 'boolean')
      filters.includeAcknowledged = includeAck

    if (search) filters.search = search

    const response = await R3sFetch(
      `/api/1.0/users/alerts/get?${serialize(filters)}`
    )
    return await handleFetchResponse(response)
  },

  async acknowledgeAlert(id, status = undefined) {
    const response = await R3sFetch(`/api/1.0/users/alerts/acknowledge`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id, status }),
    })
    return await handleFetchResponse(response)
  },

  async saveCommunicationPreferences(preferences, targetUserId) {
    const response = await R3sFetch(`/api/1.0/users/settings/communication`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ preferences, targetUserId }),
    })
    return await handleFetchResponse(response)
  },

  async getCommunicationPreferenceOptions() {
    const response = await R3sFetch(
      `/api/1.0/users/settings/communication/options`
    )
    return await handleFetchResponse(response)
  },

  async getCommunicationPreferences(targetUserId) {
    const response = await R3sFetch(
      `/api/1.0/users/settings/communication/get?targetUserId=${targetUserId}`
    )
    return await handleFetchResponse(response)
  },
}
