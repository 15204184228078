<template lang="pug">
div#tiptap.editor.mt-2(v-if="editor")
  menu-bar.editor__header(:editor="editor" :isDefaultEditor="true" :isBubbleMenu="isBubbleMenu")
  editor-content.editor__content(:editor="editor")
</template>

<script>
  import tippy from 'tippy.js'
  import 'tippy.js/dist/tippy.css'
  import { Editor, EditorContent, VueRenderer } from '@tiptap/vue-2'

  // import CharacterCount from '@tiptap/extension-character-count'
  import { Color } from '@tiptap/extension-color'
  // import HardBreak from '@tiptap/extension-hard-break'
  import Highlight from '@tiptap/extension-highlight'
  import Image from '@tiptap/extension-image'
  import Link from '@tiptap/extension-link'
  import Mention from '@tiptap/extension-mention'
  import MentionList from './tiptap/MentionList'
  import MenuBar from './tiptap/MenuBar'
  import Placeholder from '@tiptap/extension-placeholder'
  import StarterKit from '@tiptap/starter-kit'
  import ListItem from '@tiptap/extension-list-item'
  import TaskItem from '@tiptap/extension-task-item'
  import TaskList from '@tiptap/extension-task-list'
  import TextStyle from '@tiptap/extension-text-style'
  import TextAlign from '@tiptap/extension-text-align'
  import Typography from '@tiptap/extension-typography'
  import Underline from '@tiptap/extension-underline'

  export default {
    props: {
      value: {
        type: String,
        default: '',
      },
      placeholder: {
        type: String,
        default: 'Insert text here...',
      },
      allMentionUsers: {
        type: Array,
        default: () => [],
      },
      options: {
        type: Object,
        default: () => ({}),
      },
    },

    data() {
      return {
        editor: null,
      }
    },

    watch: {
      value(value) {
        // HTML
        const isSame = this.editor.getHTML() === value

        // JSON
        // const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(value)

        if (isSame) return

        this.editor.commands.setContent(value, false)
      },
    },

    computed: {
      isBubbleMenu() {
        return this.options?.theme === 'bubble'
      },
    },

    mounted() {
      this.editor = new Editor({
        content: this.value,
        extensions: [
          Color,
          Highlight.configure({
            multicolor: true,
          }),
          Image.configure({
            inline: true,
            allowBase64: true,
            HTMLAttributes: {
              class: 'tiptap-image',
            },
          }),
          Link,
          Mention.configure({
            HTMLAttributes: {
              class: 'mention',
            },
            suggestion: {
              allowSpaces: true,
              items: ({ query }) => {
                if (query) {
                  return this.allMentionUsers
                    .filter((item) =>
                      item.value.toLowerCase().startsWith(query.toLowerCase())
                    )
                    .slice(0, 5)
                } else {
                  return this.allMentionUsers.slice(0, 5)
                }
              },
              render: () => {
                let component = null
                let popup = null
                return {
                  onStart: (props) => {
                    component = new VueRenderer(MentionList, {
                      // using vue 2:
                      parent: this,
                      propsData: {
                        ...props,
                      },
                      // using vue 3:
                      // props,
                      // editor: props.editor,
                    })

                    if (!props.clientRect) {
                      return
                    }

                    // add a check to make sure that component.element is not null
                    // before creating the popup

                    if (props.clientRect) {
                      popup = tippy('#r3s', {
                        getReferenceClientRect: props.clientRect,
                        appendTo: document.body,
                        content: component.element,
                        showOnCreate: true,
                        interactive: true,
                        trigger: 'manual',
                        placement: 'bottom-start',
                        popperOptions: {
                          strategy: 'fixed',
                        },
                      })
                    }
                  },

                  onUpdate(props) {
                    component.updateProps({
                      ...props,
                    })

                    if (!props.clientRect) {
                      return
                    }

                    popup[0].setProps({
                      getReferenceClientRect: props.clientRect,
                    })
                  },

                  onKeyDown(props) {
                    if (props.event.key === 'Escape') {
                      popup[0].hide()

                      return true
                    }

                    return component.ref?.onKeyDown(props)
                  },

                  onExit() {
                    popup[0].destroy()
                    component.destroy()
                  },
                }
              },
            },
          }),
          Placeholder.configure({
            placeholder: this.placeholder,
          }),
          StarterKit.configure({
            history: true,
          }),
          TaskItem,
          ListItem.extend({
            addKeyboardShortcuts() {
              return {
                Tab: () => {
                  this.editor.chain().focus().sinkListItem('listItem').run()
                  return true
                },
              }
            },
          }),
          TaskList,
          TextAlign.configure({
            types: ['heading', 'paragraph'],
          }),
          TextStyle,
          Typography,
          Underline,
        ],

        editorProps: {
          attributes: {
            class: 'editor__content',
          },
          handleDrop: function (view, event) {
            event.preventDefault()

            // Check if a file is being dropped
            if (event.dataTransfer && event.dataTransfer.files.length) {
              const file = event.dataTransfer.files[0]

              // Check if the dropped file is an image
              if (file.type.startsWith('image/')) {
                const reader = new FileReader()

                reader.onload = function (event) {
                  const base64Image = event.target.result

                  // Create an image node and insert it into the editor
                  const imageNode = view.state.schema.nodes.image.createAndFill(
                    { src: base64Image }
                  )
                  const transaction =
                    view.state.tr.replaceSelectionWith(imageNode)
                  view.dispatch(transaction)
                }

                reader.readAsDataURL(file)
              } else {
                window.toastr.error(
                  'Invalid file type. Only images are supported.'
                )
              }
            }
          },
        },

        onUpdate: () => {
          // HTML
          this.$emit('input', this.editor.getHTML())

          // JSON
          // this.$emit('input', this.editor.getJSON())
        },

        onFocus: () => {
          this.$emit('focus')
        },
      })
    },

    beforeDestroy() {
      this.editor.destroy()
    },

    components: {
      EditorContent,
      MenuBar,
    },
  }
</script>

<style lang="scss" scoped>
  .editor {
    display: flex;
    flex-direction: column;
    height: 100%;
    color: #0d0d0d;
    background-color: white;
    border: 1px solid #e1e5eb;

    &__header {
      display: flex;
      align-items: center;
      flex: 0 0 auto;
      flex-wrap: wrap;
      border-bottom: 2px solid #e1e5eb;
    }

    &__content {
      padding: 0.5rem;
      color: #818ea3 !important;
      flex: 1 1 auto;
      overflow-x: hidden;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
    }

    &__footer {
      display: flex;
      flex: 0 0 auto;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      white-space: nowrap;
      border-top: 3px solid #0d0d0d;
      font-size: 12px;
      font-weight: 600;
      color: #0d0d0d;
      white-space: nowrap;
    }

    /* Some information about the status */
    &__status {
      display: flex;
      align-items: center;
      border-radius: 5px;

      &::before {
        content: ' ';
        flex: 0 0 auto;
        display: inline-block;
        width: 0.5rem;
        height: 0.5rem;
        background: rgba(#0d0d0d, 0.5);
        border-radius: 50%;
        margin-right: 0.5rem;
      }

      &--connecting::before {
        background: #616161;
      }

      &--connected::before {
        background: #b9f18d;
      }
    }

    &__name {
      button {
        background: none;
        border: none;
        font: inherit;
        font-size: 12px;
        font-weight: 600;
        color: #0d0d0d;
        border-radius: 0.4rem;

        &:hover {
          color: #fff;
          background-color: #0d0d0d;
        }
      }
    }
  }

  /* Basic editor styles */
  ::v-deep {
    .tiptap {
      &.ProseMirror {
        padding: 0.5rem;
        line-height: 1.5;
        color: #0d0d0d;
        overflow-x: hidden;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
      }
    }
    .tiptap-editor {
      color: #0d0d0d !important;
    }
    .ProseMirror {
      height: 100%;
      overflow: scroll;

      a {
        color: #68cef8;
      }

      ul,
      ol {
        padding: 0 1rem;
        margin-left: 1rem;
      }

      p {
        margin-left: 4px;
        margin-top: 2px;
        color: #0d0d0d !important;
      }

      h1 {
        font-size: 1.5rem;
        font-weight: 300;
        color: #0d0d0d;
      }
      h2 {
        font-size: 1.3rem;
        font-weight: 250;
        color: #0d0d0d;
      }

      code {
        background-color: rgba(#616161, 0.1);
        color: #616161;
      }

      pre {
        background: #0d0d0d;
        color: #fff;
        font-family: 'JetBrainsMono', monospace;
        border-radius: 0.5rem;

        code {
          color: inherit;
          padding: 0;
          background: none;
          font-size: 0.8rem;
        }
      }

      mark {
        background-color: #faf594;
      }

      p.is-editor-empty:first-child::before {
        color: #818ea3 !important;
        content: attr(data-placeholder);
        float: left;
        height: 0;
        pointer-events: none;
      }

      blockquote {
        margin-left: 3px;
        padding-left: 1rem;
        border-left: 2px solid rgba(#0d0d0d, 0.1);
      }

      hr {
        border: none;
        border-top: 2px solid rgba(#0d0d0d, 0.1);
        margin: 2rem 0;
      }

      ul[data-type='taskList'] {
        list-style: none;
        padding: 0;

        li {
          display: flex;
          align-items: center;

          > label {
            flex: 0 0 auto;
            margin-right: 0.5rem;
          }
        }
      }
    }

    span {
      &.mention {
        background: #d3e1eb !important;
        border-radius: 0.4rem;
        box-decoration-break: clone;
        color: #5a6169 !important;
        padding: 0.1rem 0.3rem;
      }
    }
    .tiptap-image {
      max-width: 100%;
      height: auto;
    }

    .resize-cursor {
      cursor: ew-resize;
      cursor: col-resize;
    }
  }
</style>
