<template lang="pug">
  div.pm-timeline-changelog
    div.d-flex
      strong.nowrap {{ data.details.title }}
      div.ml-2(v-if="isInternalUser && data.details.record")
        i.fal.fa-info-circle(:id="`change-log-detail-${data.id}`")
        b-tooltip(:target="`change-log-detail-${data.id}`",custom-class="text-left")
          div(style="font-size: 9px;",v-html="getPrettyJson(data.details.record || {})")
    div.p-2.bg-light
      div.d-flex.align-items-center
        user-avatar.mr-1(
          :id="`pm-timeline-changelog-${data.id}-${data.user_id}`",
          height="25px",
          max-height="25",
          :should-show-close="false",
          :user-id="data.user_id")
        h6.m-0 {{ $store.state.getUserName(data.user_id) }}
        div.ml-auto.small.text-gray {{ getFormattedDate(data.created_at, 'MMM D, YYYY h:mm a') }}
      div.mt-2
        div(:id="`change-log-item-${data.id}`",v-html="displayInfo") 
</template>

<script>
  import { mapState } from 'vuex'
  import TimeHelpers from '../../../factories/TimeHelpers'

  export default {
    props: {
      // Same thing as 'log' prop in ./ChangeLogTable component
      // {
      //   id: 1,
      //   user_id: 1,
      //   created_at: '2021-06-01T00:00:00.000Z',
      //   details: {
      //     title: 'title',
      //     info: 'info',
      //     record: {},
      //   },
      // }
      data: { type: Object, required: true },
    },

    computed: {
      ...mapState({
        isInternalUser: (_, getters) => getters.isInternalUser,
      }),

      displayInfo() {
        return this.data.details.info &&
          !this.data.details.info.toLowerCase().includes('comment info')
          ? this.data.details.info
          : 'Comment redacted'
      },
    },

    methods: {
      getFormattedDate: TimeHelpers.getFormattedDate,

      getPrettyJson(obj) {
        return this.$store.state.getPrettyJson(obj)
      },
    },
  }
</script>
