<template lang="pug">
  div.modal.fade(tabindex="-1",role="dialog",aria-hidden="true")
    div.modal-dialog(role="document")
      div.modal-content
        form(@submit="createContact")
          div.modal-header
            h5.modal-title Add Key Contact
            button.close(type="button",data-dismiss="modal",aria-label="Close")
              span(aria-hidden="true") &times;
          div.modal-body
            div.card.card-small.border.no-shadow.mb-4
              div.card-body
                div.form-group.mb-2.d-flex.justify-content-center
                  div.custom-control.custom-checkbox
                    input#existing-or-new-category.custom-control-input(type="checkbox",v-model="createNewCategory")
                    label.nowrap.custom-control-label(for="existing-or-new-category") Is New Group?
                div.form-group(v-if="createNewCategory")
                  label New Group Name
                  input.form-control(v-model="contact.group_name")
                div.form-group(v-else)
                  label Select Group
                  select.form-control(v-model="contact.group_name")
                    option(:value="null") Select existing group...
                    option(v-for="category in categories") {{ category }}

            div.form-group.mb-3
              label Key Contact Role (i.e. Enterprise Risk, Access Control, etc.)
              input.form-control(v-model="contact.role_name")
            div.form-group.mb-3
              label User
              select.form-control(v-model="contact.user_id")
                option(:value="null") Select Contact User...
                option(v-for="user in $store.state.team.users",:value="user.id") {{ user.name || user.username_email }}
            
          div.modal-footer
            button.btn.btn-secondary(type="button",data-dismiss="modal") Cancel
            button.btn.btn-primary(type="submit") Create Key Contact
</template>

<script>
  import $ from 'jquery'
  import ApiAssessments from '../../factories/ApiAssessments'

  export default {
    props: {
      // projectId: { type: [Number, String] },
      categories: { type: Array },
    },

    data() {
      return {
        createNewCategory: false,
        contact: getDefaultContactData(),
      }
    },

    methods: {
      async createContact(evt) {
        try {
          evt.preventDefault()

          if (!(this.contact.group_name && this.contact.role_name))
            return window.toastr.error(
              `Please enter at least a category/group and role to proceed.`
            )

          await ApiAssessments.updateKeyContact(this.contact)
          window.toastr.success(`Successfully created your key contact!`)

          this.$emit('created')
          $(`#${this.$el.id}`).modal('toggle')
        } catch (err) {
          window.toastr.error(err.message)
        }
      },
    },

    async mounted() {
      $(`#${this.$el.id}`).on('shown.bs.modal', async () => {
        this.createNewCategory = false
        this.contact = getDefaultContactData()
      })
    },
  }

  function getDefaultContactData() {
    return {
      group_name: null,
      role_name: null,
      user_id: null,
    }
  }
</script>
