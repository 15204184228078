<template lang="pug">
div
  i Schedule Your Report: Define the frequency and timing for your report to be sent.
  div.col-md-12
    div.form-row
      div.form-group.ml-1.my-3.col-12
        div.custom-control.custom-toggle-sm.custom-toggle(:id="`recurring-container-${_uid}`")
          input.custom-control-input(
            type="checkbox"
            :id="`recurring-toggle-${_uid}`"
            :name="`recurring-toggle-${_uid}`"
            v-model="mutableIsRecurring"
            :disabled="!userCanEdit"
            @change="updateInfo('isRecurring', mutableIsRecurring)"
          )
          label.custom-control-label(:for="`recurring-toggle-${_uid}`") Send on Schedule
  div(v-if="mutableIsRecurring")
    div.col-12
      h6 Set Schedule
      div.pl-3
        div.form-row
          div
            label.m-0 Start Date
            datepicker-shards(
              :show-clear="true",
              :input-id="`event-start-${_uid}`",
              v-model="mutableStartDate"
              :disabled="!userCanEdit")
        div.form-row.my-3
          div
            label.m-0 Time of day
            div.d-flex.align-items-center
              b-form-timepicker(
                :id="`event-start-time-${_uid}`",
                v-model="mutableTime"
                :disabled="!userCanEdit")
              p.nowrap ({{ mutableStartDate?.toTimeString().split('(')[1]?.split(')')[0]  || ''}})
        div.form-row
          calendar-recurring-picker(
            v-model="mutableRecurringOptions",
            :date="mutableStartDate" 
            :showUntil="false" 
            :timeToDisplay="mutableStartDate.toTimeString()"
            overviewIntroText="This report will be sent"
            :disabled="!userCanEdit"
            @input="updateInfo('recurringOptions', mutableRecurringOptions)"
          )
    hr
    div.col-12.mt-3
      email-report-form.w-100(
        :emailSubject="emailSubject"
        :emailMessage="emailMessage"
        :newEmailAddresses="newEmailAddresses"
        :fullCircleUserIds="fullCircleUserIds"
        :distributionGroupIds="distributionGroupIds"
        :userCanEdit="userCanEdit"
        @update="updateEmailData"
      )
  </template>

<script>
  import { mapState } from 'vuex'
  import Utilities from '@risk3sixty/utilities'
  import EmailReportForm from '../../reusable/reports/EmailReportForm.vue'

  const moment = Utilities.Dates.moment
  const currentDate = new Date()
  currentDate.setSeconds(0)
  currentDate.setMilliseconds(0)

  export default {
    props: {
      isRecurring: { type: Boolean },
      startDate: { type: [String, Date] },
      recurringOptions: { type: Object },
      emailData: { type: Object },
      userCanEdit: { type: Boolean },
    },

    watch: {
      isRecurring: {
        immediate: true,
        handler(newValue) {
          if (newValue) {
            this.mutableIsRecurring = newValue
          } else {
            this.mutableIsRecurring = false
          }
        },
      },
      recurringOptions: {
        immediate: true,
        handler(newValue) {
          if (newValue && Object.keys(newValue).length > 0) {
            this.mutableRecurringOptions = newValue
          } else {
            this.mutableRecurringOptions = {}
          }
        },
      },

      'emailData.newEmailAddresses': {
        immediate: true,
        deep: true,
        handler(newValue) {
          this.newEmailAddresses = newValue
        },
      },

      'emailData.fullCircleUserIds': {
        immediate: true,
        deep: true,
        handler(newValue) {
          this.fullCircleUserIds = newValue
        },
      },

      'emailData.distributionGroupIds': {
        immediate: true,
        deep: true,
        handler(newValue) {
          this.distributionGroupIds = newValue
        },
      },

      'emailData.emailSubject': {
        immediate: true,
        handler(newValue) {
          this.emailSubject = newValue
        },
      },

      'emailData.emailMessage': {
        immediate: true,
        handler(newValue) {
          this.emailMessage = newValue
        },
      },
    },

    data() {
      return {
        mutableIsRecurring: false,
        mutableRecurringOptions: {},

        //Email Data
        newEmailAddresses: [],
        fullCircleUserIds: [],
        distributionGroupIds: [],
        emailSubject: '',
        emailMessage: '',
      }
    },

    computed: {
      ...mapState({}),
      mutableStartDate: {
        get() {
          return this.startDate
            ? new Date(moment(this.startDate).utc().toString())
            : currentDate
        },
        set(val) {
          const newStartDate = moment(val).utc()
          const [hours, minutes] = this.mutableTime.split(':')
          newStartDate.hour(hours)
          newStartDate.minute(minutes)
          newStartDate.second(0)
          newStartDate.millisecond(0)
          this.updateInfo('startDate', new Date(newStartDate.toString()))
        },
      },
      mutableTime: {
        get() {
          const hours = this.mutableStartDate?.getHours() || 0
          const minutes = this.mutableStartDate?.getMinutes() || 0
          return `${hours}:${minutes}`
        },
        set(val) {
          const [hours, minutes] = val.split(':')
          this.mutableStartDate.setHours(hours)
          this.mutableStartDate.setMinutes(minutes)
          this.mutableStartDate.setSeconds(0)
          this.mutableStartDate.setMilliseconds(0)
          this.updateInfo('startDate', this.mutableStartDate)
        },
      },
    },

    methods: {
      moment,
      updateInfo(key, val) {
        this.$emit('update', key, val)
      },

      updateEmailData(key, value) {
        this[key] = value

        this.$emit('update', 'emailData', {
          emailSubject: this.emailSubject,
          emailMessage: this.emailMessage,
          newEmailAddresses: this.newEmailAddresses,
          fullCircleUserIds: this.fullCircleUserIds,
          distributionGroupIds: this.distributionGroupIds,
        })
      },
    },

    components: {
      EmailReportForm,
    },
  }
</script>
