import { render, staticRenderFns } from "./MentionList.vue?vue&type=template&id=53379b1e&scoped=true&lang=pug"
import script from "./MentionList.vue?vue&type=script&lang=js"
export * from "./MentionList.vue?vue&type=script&lang=js"
import style0 from "./MentionList.vue?vue&type=style&index=0&id=53379b1e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53379b1e",
  null
  
)

export default component.exports