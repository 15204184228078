<template lang="pug">
div
  h6 Email Info
  div.pl-2
    div.form-group.mt-2
      label(for="send-email-recipients") Recipients:
      assignee-dropdown(
        :includeTeamUsers="true"
        :includeInternalUsers="true"
        :includeDistributionGroups="true"
        placeholder="Add Recipient..."
        @input="updateEmailAddresses('add', ...arguments)"
      )

      div.mt-2
        div(v-if="[...mutableEmailAddresses, ...mutableFullCircleUsers, ...mutableDistributionGroups].length === 0")
          small No recipients added...
        template(v-else)
          div.d-flex(v-for="email in mutableEmailAddresses")
            strong
              | {{ email }}
              | #[i.clickable.fal.fa-times.text-danger(@click="updateEmailAddresses('delete', AssigneeType.EMAIL, email)")]
          div.d-flex(v-for="{id, name, username_email} in mutableFullCircleUsers")
            div 
              | #[strong {{ name }}] ({{ username_email }})
              | #[i.clickable.fal.fa-times.text-danger(@click="updateEmailAddresses('delete', AssigneeType.TEAM_USER, id)")]
          div.d-flex(v-for="{distribution_group_id, name} in mutableDistributionGroups")
            strong
              | {{ name }}
              | #[i.clickable.fal.fa-times.text-danger(@click="updateEmailAddresses('delete', AssigneeType.DISTRIBUTION_GROUP, distribution_group_id)")]

    div.form-group.mt
      label(for="send-email-subject") Subject:
      input#send-email-subject.form-control(
        v-model="mutableEmailSubject" 
        :disabled="!userCanEdit")

    div.form-group.mt
      label(for="send-email-message") Message:
      rich-text-editor#send-email-message(
        v-model="mutableEmailMessage" 
        :disabled="!userCanEdit")
</template>

<script>
  import { mapState } from 'vuex'
  import ApiAuth from '../../../factories/ApiAuth'
  import { AssigneeType } from '../AssigneeDropdown.vue'

  export default {
    props: {
      emailSubject: { type: String, required: true },
      emailMessage: { type: String, required: true },
      newEmailAddresses: { type: Array, default: () => [] },
      fullCircleUserIds: { type: Array, default: () => [] },
      distributionGroupIds: { type: Array, default: () => [] },
      userCanEdit: { type: Boolean, default: true },
    },

    data() {
      return {
        AssigneeType,
      }
    },

    computed: {
      ...mapState({
        teamAndInternalUsers: (state) => state.team.teamAndInternalUsers,
        distributionGroups: (state) => state.team.distributionGroups,
      }),

      mutableEmailSubject: {
        get() {
          return this.emailSubject
        },
        set(value) {
          this.$emit('update', 'emailSubject', value)
        },
      },

      mutableEmailMessage: {
        get() {
          return this.emailMessage
        },
        set(value) {
          this.$emit('update', 'emailMessage', value)
        },
      },

      mutableFullCircleUsers: {
        get() {
          return this.fullCircleUserIds.map((id) => {
            const matchedUser = this.teamAndInternalUsers.find(
              (user) => user.id === id
            )
            return {
              id: matchedUser.id,
              name: matchedUser.name,
              username_email: matchedUser.username_email,
            }
          })
        },
        set(value) {
          this.$emit('update', 'fullCircleUserIds', value)
        },
      },

      mutableDistributionGroups: {
        get() {
          return this.distributionGroupIds.map((id) => {
            const matchedGroup = this.distributionGroups.find(
              (group) => group.id === id
            )
            return {
              distribution_group_id: matchedGroup.id,
              name: matchedGroup.name,
            }
          })
        },
        set(value) {
          this.$emit('update', 'distributionGroupIds', value)
        },
      },

      mutableEmailAddresses: {
        get() {
          return this.newEmailAddresses
        },
        set(value) {
          this.$emit('update', 'newEmailAddresses', value)
        },
      },
    },

    methods: {
      isValidEmail: ApiAuth.isValidEmail,

      updateEmailAddresses(operation, type, value) {
        value = value.toString()

        switch (type) {
          case AssigneeType.DISTRIBUTION_GROUP: {
            const distGroupIdsSet = new Set(this.distributionGroupIds)
            if (operation == 'add') {
              distGroupIdsSet.add(value)
            } else {
              distGroupIdsSet.delete(value)
            }
            this.mutableDistributionGroups = Array.from(distGroupIdsSet)
            break
          }
          case AssigneeType.TEAM_USER:
          case AssigneeType.INTERNAL_USER: {
            const fullCircleUserIdsSet = new Set(this.fullCircleUserIds)
            if (operation == 'add') {
              fullCircleUserIdsSet.add(value)
            } else {
              fullCircleUserIdsSet.delete(value)
            }
            this.mutableFullCircleUsers = Array.from(fullCircleUserIdsSet)
            break
          }
          case AssigneeType.EMAIL: {
            const emailUserAdressesSet = new Set(this.newEmailAddresses)
            if (operation == 'add') {
              if (!this.isValidEmail(value)) {
                return window.toastr.error('Please enter valid email.')
              }
              emailUserAdressesSet.add(value)
            } else {
              emailUserAdressesSet.delete(value)
            }
            this.mutableEmailAddresses = Array.from(emailUserAdressesSet)
            break
          }
        }
      },
    },
  }
</script>
