<template lang="pug">
div.modal.fade(role="dialog",aria-hidden="true")
  div.modal-dialog.modal-lg(role="document")
    div.modal-content
      form 
        div.modal-header
          h5.modal-title Report History
          button.close(type="button",data-dismiss="modal",aria-label="Close")
            span(aria-hidden="true") &times;
        div.modal-body
          div.row(v-if="paginatedHistory.data.length == 0")
            div.col-12
              p.mb-0 This report has no history yet.
          table.table.table-striped.table-bordered.mb-0(v-else)
            thead.bg-light
              tr
                th.border-0.nowrap(scope='col') User
                th.border-0.nowrap(scope='col') Action
                th.border-0.nowrap(scope='col') Date
                th.border-0.nowrap(scope='col') Download
            tbody
              tr(v-for="item in paginatedHistory.data")
                td
                  user-avatar(
                    v-if="item.created_by",
                    max-height="30px",
                    :id="`report-history-created-by-${item.id}`",
                    :should-show-close="false",
                    :user-id="item.created_by")
                td {{ capitalize(item.action) }}
                  template(v-if="item.action === 'email' && item.configuration.emailData")
                    i.fal.fa-info-circle.ml-1(:id="`report-history-email-data-${item.id}`")
                    b-tooltip(:target="`report-history-email-data-${item.id}`")
                      | #[div(v-html="prettyEmailData(item.configuration.emailData)")]
                td {{ getFormattedDate(item.created_at, 'MMMM D, YYYY h:mm a') }}
                td
                  a(:id="`report-history-download-${item.id}`" @click="downloadReport(item.external_file_name)")
                    loader-inline(v-if="downloadingReport")
                    i.fas.fa-download(v-else)
                    b-tooltip(:target="`report-history-download-${item.id}`") Download Report
              tr(v-if="paginatedHistory.numberPages > 1")
                td(colspan="100%")
                  pagination(
                    :info="paginatedHistory"
                    @changePage="(page) => this.page = page"
                    @changePerPage="(perPage) => this.perPage = perPage")
        div.modal-footer
          button.btn.btn-secondary(@click="closeModal" type="button",data-dismiss="modal") Close
</template>

<script>
  import $ from 'jquery'
  import Utilities from '@risk3sixty/utilities'
  import DomHelpers from '../../../factories/DomHelpers'
  import TimeHelpers from '../../../factories/TimeHelpers'

  export default {
    props: {
      reportHistory: { type: Array, required: true },
    },

    data() {
      return {
        perPage: 10,
        page: 1,

        downloadingReport: false,
      }
    },

    computed: {
      paginatedHistory() {
        const paginationData = Utilities.Arrays.paginateArray(
          this.reportHistory,
          this.perPage,
          this.page
        )

        return {
          data: paginationData.data,
          currentPage: paginationData.currentPage,
          totalCount: paginationData.dataLength,
          numberPages: paginationData.numberOfPages,
          perPage: this.perPage,
        }
      },
    },

    methods: {
      capitalize: Utilities.Strings.capitalize,
      getFormattedDate: TimeHelpers.getFormattedDate,

      closeModal() {
        $(`#${this.$el.id}`).modal('hide')
      },

      downloadReport(filename) {
        try {
          this.downloadingReport = true
          DomHelpers.downloadUri(
            `/file/s3/${encodeURIComponent(filename)}`,
            decodeURIComponent(filename)
          )
        } catch (err) {
          window.toastr.error(err.message)
        } finally {
          this.downloadingReport = false
        }
      },

      prettyEmailData(emailData) {
        return Object.keys(emailData)
          .map((key) => {
            return emailData[key]
              ? `<p><b>${key}</b>: ${emailData[key]}</p>`
              : ''
          })
          .join(' ')
      },
    },

    mounted() {
      $(`#${this.$el.id}`).appendTo('body')
      $(`#${this.$el.id}`).on('show.bs.modal', async () => {})
    },

    beforeDestroy() {
      $(`#${this.$el.id}`).remove()
    },

    components: {},
  }
</script>
