import R3sFetch from './R3sFetch'
import R3sSocket from './R3sSocket'
import { handleFetchResponse } from './ApiHelpers'

export async function switchOrgs(vueInst, teamId, redirectUrl = null) {
  vueInst.$store.commit('SET_GLOBAL_RESET', true)
  const { redirect } = await switchFetch(teamId, redirectUrl)
  await vueInst.$store.dispatch('init')
  vueInst.$store.commit('SET_GLOBAL_RESET', false)
  vueInst.$store.commit('IS_VALID_REDIRECTING_HIT', true)
  await vueInst.$router.push('/redirecting')
  await vueInst.$router.push(redirect)
  vueInst.$store.commit('IS_VALID_REDIRECTING_HIT', false)
}

async function switchFetch(teamId, redirectUrl = null) {
  const response = await R3sFetch(
    `/api/1.0/teams/switch?id=${teamId}&redirect=${
      redirectUrl || ''
    }&socketId=${R3sSocket.id || ''}`
  )
  return await handleFetchResponse(response)
}

export default {
  switch: switchOrgs,

  async getAll(getFromDb = '', getInactive = '') {
    const response = await R3sFetch(
      `/api/1.0/teams/getall?db=${getFromDb}&inactive=${getInactive}`
    )
    return await handleFetchResponse(response)
  },

  async getAllHierarchy(includeInactives = '') {
    const response = await R3sFetch(
      `/api/1.0/teams/getall/hierarchy?inactive=${includeInactives || ''}`
    )
    return await handleFetchResponse(response)
  },

  async createNew(parentTeamId, name, type, permissions = []) {
    const response = await R3sFetch(`/api/1.0/teams/create`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ teamId: parentTeamId, name, type, permissions }),
    })
    return await handleFetchResponse(response)
  },

  async update(record) {
    const response = await R3sFetch(`/api/1.0/teams/update`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ record }),
    })
    return await handleFetchResponse(response)
  },

  async inviteTeamMember(userInfo) {
    const response = await R3sFetch(`/api/1.0/teams/invite`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(userInfo),
    })
    return await handleFetchResponse(response)
  },

  async resendVerificationMailer(userId) {
    const response = await R3sFetch(`/api/1.0/teams/invite/resend`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ user_id: userId }),
    })
    return await handleFetchResponse(response)
  },

  async getTeamUsers(includeInternal = null) {
    const response = await R3sFetch(
      `/api/1.0/teams/users/get${includeInternal ? '?internal=true' : ''}`
    )
    return await handleFetchResponse(response)
  },

  async getTeamContactUsers() {
    const response = await R3sFetch(`/api/1.0/teams/contact/users/get`)
    return await handleFetchResponse(response)
  },

  async getTeamUploadUsers() {
    const response = await R3sFetch(`/api/1.0/teams/upload/users/get`)
    return await handleFetchResponse(response)
  },

  // async getTeamPolicyPortalUsers() {
  //   const response = await R3sFetch(`/api/1.0/teams/policy/portal/users/get`)
  //   return await handleFetchResponse(response)
  // },

  async getApiKeys(status = 'Active', allKeys = false) {
    const response = await R3sFetch(
      `/api/1.0/teams/apikeys?status=${status}&all=${allKeys || ''}`
    )
    return await handleFetchResponse(response)
  },

  async getApiKeyInfo(id) {
    const response = await R3sFetch(`/api/1.0/teams/apikey/get?id=${id}`)
    return await handleFetchResponse(response)
  },

  async getAuditLogs(page = 1, pageSize = 20, filters = null) {
    const response = await R3sFetch(
      `/api/1.0/teams/audit/logs?page=${page}&pageSize=${pageSize}&filters=${encodeURIComponent(
        JSON.stringify(filters)
      )}`
    )
    return await handleFetchResponse(response)
  },

  async createApiKey() {
    const response = await R3sFetch(`/api/1.0/teams/apikey/create`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({}),
    })
    return await handleFetchResponse(response)
  },

  async deactivateApiKey(apiKeyId) {
    const response = await R3sFetch(`/api/1.0/teams/apikey/deactivate`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id: apiKeyId }),
    })
    return await handleFetchResponse(response)
  },

  async addInternalUser(teamId, userId, remove = false) {
    const response = await R3sFetch(`/api/1.0/teams/users/internal/add`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ teamId, userId, remove }),
    })
    return await handleFetchResponse(response)
  },

  async updateAllInternalUsers(userIdAry) {
    const response = await R3sFetch(`/api/1.0/teams/users/internal/update`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ ids: userIdAry }),
    })
    return await handleFetchResponse(response)
  },

  async getAllEnums() {
    const response = await R3sFetch(`/api/1.0/teams/enums/list`)
    return await handleFetchResponse(response)
  },

  async getEnum(id) {
    const response = await R3sFetch(`/api/1.0/teams/enum/get?id=${id}`)
    return await handleFetchResponse(response)
  },

  async createUpdateEnum({ id, name, values }) {
    const response = await R3sFetch(`/api/1.0/teams/enum/create`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id, name, values }),
    })
    return await handleFetchResponse(response)
  },
}
