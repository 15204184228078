<template lang="pug">
  div.pm-timeline
    div.small
      template(v-if="canAddComments")
        div.form-group.mb-2
          rich-text-editor.border-bottom(
            v-model="newComment"
            :users="mentionUsers"
            @mentionReplaced="addMention")
        div.form-group.m-0.text-center
          button.btn.btn-sm.btn-primary(type="button",@click="createComment()") Add Comment

      file-uploader.mt-2(
        v-if="canAddFiles"
        size="sm",
        defaultMessage="Click or drop files here to upload.",
        @added="uploadedFile")

      div.py-2(v-if="items.length === 0")
        i No timeline items...
      template(v-else)
        div.my-2(v-if="hasFilter")
          select-dropdown-filter(
            :class="pmFilter && 'filter-active'"
            v-model="pmFilter"
            text="Filter Timeline"
            icon="fa fa-filter"
            :options="filterOptions")
        div(v-if="hasFilter && filteredItems.length === 0")
          i No items with filters selected...
        div(v-else-if="filteredItems.length === 0")
          i No timeline items...
        div.pm-timeline-item.d-flex.align-items-start(v-else,v-for="item in filteredItems")
          risk-register-data-circle.timeline-icon.pr-2(
            v-if="item.type === 'changeLog' && item.data.details.title === 'Update Item Metric'",
            size="25",
            :type="getDataCircleType(item)",
            :value="getDataCircleValue(item)")
          i.timeline-icon.fa.fa-2x.pr-2(v-else,:class="getIconClass(item.type)")
          component.w-100(
            :is="getComponentType(item.type)",
            :data="item.data",
            @deleteFile="deleteFile",
            @updateComment="updateComment",
            @deleteComment="deleteComment")
</template>

<script>
  import { mapState } from 'vuex'
  import ChangeLog from './ChangeLog'
  import Comment from './Comment'
  import File from './File'
  import ApiComments from '../../../factories/ApiComments'
  import ApiFiles from '../../../factories/ApiFiles'

  export default {
    props: {
      // Example:
      // [
      //   { type: 'comment', data: { id: 1, ... } },
      //   { type: 'changeLog', data: { id: 1, ... } },
      //   ...
      // ]
      items: { type: Array, required: true },

      canAddComments: { type: Boolean, default: true },
      canAddFiles: { type: Boolean, default: true },

      // if provided, we'll update info on backend here for comments
      // or files being added
      entityTable: { type: String, default: null },
      entityId: { type: [Number, String], default: null },
      filterOptions: {
        type: Array,
        default: () => [
          { text: 'Activity Log', value: 'changeLog' },
          { text: 'Comments', value: 'comment' },
          { text: 'Files', value: 'file' },
        ],
      },
    },

    data() {
      return {
        pmFilter: null,
        newComment: null,
        newCommentMentions: [],
      }
    },

    computed: {
      ...mapState({
        mentionUsers: (state) => state.team.teamAndInternalUsers,
      }),

      hasFilter() {
        return (this.filterOptions || []).length > 1
      },

      filteredItems() {
        return (this.items || []).filter((i) => {
          if (this.pmFilter) return i.type === this.pmFilter
          return true
        })
      },
    },

    methods: {
      async uploadedFile() {
        if (this.entityTable && this.entityId)
          await this.uploadEntityFile(...arguments)
        this.$emit('uploadedFile', ...arguments)
      },

      async deleteFile(fileId) {
        if (this.entityTable && this.entityId)
          await this.deleteEntityFile(fileId)
        this.$emit('deleteFile', fileId)
      },

      async createComment() {
        if (this.entityTable && this.entityId) {
          await this.addUpdateEntityComment({
            comment: this.newComment,
            mentions: this.newCommentMentions,
          })
        }
        this.$emit('createComment', {
          comment: this.newComment,
          mentions: this.newCommentMentions,
        })
        this.resetNewCommentInfo()
      },

      async updateComment(info) {
        if (this.entityTable && this.entityId)
          await this.addUpdateEntityComment(info)
        this.$emit('updateComment', info)
      },

      async deleteComment(id) {
        if (this.entityTable && this.entityId)
          await this.deleteEntityComment(id)
        this.$emit('deleteComment', id)
      },

      addMention(item) {
        this.newCommentMentions.push({ key: item.value, email: item.id })
      },

      resetNewCommentInfo() {
        this.newComment = ''
        this.newCommentMentions = []
        this.addNewCommentToggle = false
      },

      getComponentType(itemType) {
        switch (itemType) {
          case 'comment':
            return Comment
          case 'file':
            return File
          default:
            // 'changeLog'
            return ChangeLog
        }
      },

      getIconClass(itemType) {
        switch (itemType) {
          case 'comment':
            return 'fa-comments'
          case 'file':
            return 'fa-file-search'
          case 'changeLog':
            return 'fa-history'
          default:
            return 'fa-circle'
        }
      },

      getDataCircleValue(item) {
        const field = Object.keys(item.data.details.record).find(
          (k) => !['id', 'team_id'].includes(k)
        )
        return item.data.details.record[field]
      },

      getDataCircleType(item) {
        const field = Object.keys(item.data.details.record).find(
          (k) => !['id', 'team_id'].includes(k)
        )
        return field.split('_')[0]
      },

      async addUpdateEntityComment(
        { id, comment, mentions, internalOnly },
        deleteComment = false
      ) {
        try {
          await ApiComments.createOrUpdateAndLink(
            {
              id,
              comment,
              mentions,
              internalOnly,
              table: this.entityTable,
              entityId: this.entityId,
            },
            deleteComment
          )
        } catch (err) {
          window.toastr.error(err.message)
        }
      },

      async deleteEntityComment(id) {
        await this.addUpdateEntityComment({ id }, true)
      },

      async uploadEntityFile([, file]) {
        try {
          this.isFileUploading = false
          const { filename } = file

          // TODO: do files need to be private scoped here?
          const { files } = await ApiFiles.saveFile(
            { name: filename },
            'private'
          )
          await Promise.all(
            files.map(async (file) => {
              await this.addUpdateEntityFile(file.id)
            })
          )
        } catch (err) {
          window.toastr.error(err.message)
        }
      },

      async addUpdateEntityFile(id, del = false) {
        try {
          await ApiFiles.createOrUpdateAndLinkToEntity(
            {
              id,
              table: this.entityTable,
              entityId: this.entityId,
            },
            del
          )
        } catch (err) {
          window.toastr.error(err.message)
        }
      },

      async deleteEntityFile(id) {
        await this.addUpdateEntityFile(id, true)
      },
    },

    components: {
      ChangeLog,
      Comment,
      File,
    },
  }
</script>

<style lang="scss">
  .pm-timeline {
    position: relative;

    .pm-timeline-item {
      position: relative;
      padding-top: 10px;
      padding-bottom: 10px;
      // margin-left: 10px;

      &:first-child {
        padding-top: 0px;
      }

      &::before {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 10px;
        display: block;
        width: 2px;
        content: '';
        background-color: #e1e4e8;
      }

      .timeline-icon {
        background: #fff;
        color: #e1e4e8;
        z-index: 1;
      }
    }
  }
</style>
