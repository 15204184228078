<template lang="pug">
  div
    //- div.small.text-right
    //-   a.small(@click="addNewCommentToggle = !addNewCommentToggle") (add comment)
    div.card.card-small.mb-3.no-shadow.border-bottom(v-if="isCommenting")
      div.card-body.p-0
        div.form-group.mb-0
          rich-text-editor.border-0(
            :id="`new-comment-${_uid}`"
            ref="newCommentEditor"
            :value="value"
            @input="v => $emit('input', v)"
            :users="computedMentionUsers"
            @mentionReplaced="addMention")
      div.card-footer.py-2.border-left.border-left.border-right
        div.row.small-gutters(:class="!onlyInternal ? '' : 'd-flex justify-content-center'")
          div.col-12.col-md-6.d-flex.align-items-center(v-if="!onlyInternal && includeInternalFlag")
            div.custom-control.custom-checkbox.mt-2(v-if="isInternalUser")
              input.custom-control-input(
                :id="`new-comment-internal-${_uid}`"
                type="checkbox"
                v-model="newCommentInternal")
              label.nowrap.custom-control-label(:for="`new-comment-internal-${_uid}`")
                | Comment is internal only?
          div.col-12.col-md-6.d-flex.align-items-center(:class="!onlyInternal ? 'justify-content-end' : 'justify-content-center'")
            button.btn.btn-vsm.btn-danger.mr-2(v-if="showCancel" type="button",@click="reset(), $emit('cancelComment')")
              | Cancel
            button.btn.btn-vsm.btn-primary(type="button",@click="createComment()")
              | Add Comment
    div.request-card-section
      div(v-if="comments.length === 0")
        i No comments added yet
      div.card.card-small.no-shadow.mb-2.d-flex.assessment-comment(
        v-else
        v-for="comment in comments")
          comment-card.border(
            :comment="comment",
            :mention-users="computedMentionUsers",
            :only-internal="onlyInternal"
            @update="updateComment",
            @delete="deleteComment")
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    props: {
      comments: { type: Array },
      mentionUsers: { type: Array, default: null },
      isCommenting: { type: Boolean, default: true },

      // string that is a new comment if provided or changed
      value: { type: String, default: null },
      onlyInternal: { type: Boolean, default: false },
      includeInternalFlag: { type: Boolean, default: true },
      showCancel: { type: Boolean, default: false },
    },

    data() {
      return {
        // addNewCommentToggle: true,
        newCommentInternal: this.onlyInternal,
        newCommentMentions: [],
      }
    },

    watch: {
      isCommenting: {
        handler() {
          if (this.isCommenting) {
            this.$nextTick(() => {
              this.$refs.newCommentEditor.focus(true)
            })
          }
        },
      },
    },

    computed: {
      ...mapState({
        isInternalUser: (_, getters) => getters.isInternalUser,

        teamAndInternalUsers: (state) => state.team.teamAndInternalUsers,
      }),

      computedMentionUsers() {
        return this.mentionUsers && this.mentionUsers.length > 0
          ? this.mentionUsers
          : this.teamAndInternalUsers
      },
    },

    methods: {
      addMention(item) {
        this.newCommentMentions.push({ key: item.value, email: item.id })
      },

      reset() {
        this.$emit('input', '')
        this.newCommentMentions = []
        // this.addNewCommentToggle = false
        this.newCommentInternal = this.onlyInternal
      },

      createComment() {
        this.$emit('createComment', {
          comment: this.value,
          mentions: this.newCommentMentions,
          internalOnly: this.onlyInternal ? true : this.newCommentInternal,
        })
        this.reset()
      },

      deleteComment(commentRecord) {
        this.$emit('deleteComment', commentRecord.id)
        this.reset()
      },

      updateComment(commentRecord, mentionsAry) {
        this.$emit('updateComment', {
          id: commentRecord.id,
          comment: commentRecord.comment,
          mentions: mentionsAry,
          internalOnly: commentRecord.internal_only,
        })
        this.reset()
      },
    },
  }
</script>
