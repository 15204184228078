export default {
  SET_REPORT_TYPES(state, typesObj) {
    state.reporting.types = typesObj
  },

  SET_CURRENT_REPORT_TYPE(state, type) {
    state.reporting.currentType = type
  },

  SET_REPORTS(state, reports) {
    state.reporting.reports = reports
  },

  SET_REPORT_SORTING(state, { key, direction }) {
    state.reporting.sorting.reports = {}
    state.reporting.sorting.reports[key] = direction
  },

  SET_REPORTS_PAGE(state, page) {
    state.reporting.pagination.reports.page = page
  },

  SET_REPORTS_PER_PAGE(state, perPage) {
    state.reporting.pagination.reports.perPage = perPage
  },

  SET_DISTRIBUTION_GROUPS(state, distributionGroups) {
    state.reporting.distributionGroups = distributionGroups
  },

  SET_CURRENT_DISTRIBUTION_GROUP(state, distributionGroup) {
    state.reporting.currentDistributionGroup = distributionGroup
  },

  SET_DISTRIBUTION_GROUP_USER_SORTING(state, { key, val }) {
    state.reporting.sorting.currentDistributionGroupUsers = {}
    state.reporting.sorting.currentDistributionGroupUsers[key] = val
  },

  SET_CURRENT_DISTRIBUTION_GROUP_CHANGE_LOG(state, changeLog) {
    state.reporting.currentDistributionGroupChangeLog = changeLog
  },
}
