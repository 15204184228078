<template lang="pug">
v-select.typeahead-input-new(
  :disabled="disabled"
  :placeholder="placeholder"
  :filterBy="() => true"
  :options="mutableOptions"
  v-model="mutableValue"
  @search="search"
  @search:blur="resetOptions")
  template(v-slot:option="option")
    slot(name="option" v-bind="option")
  template(v-slot:no-options="{ search, searching }")
    i {{searchMessage(searching)}}
</template>

<script>
  import Utilities from '@risk3sixty/utilities'
  import R3sFetch from '../../factories/R3sFetch'
  import { handleFetchResponse } from '../../factories/ApiHelpers'

  const debounce = Utilities.debounce

  // NOTE: see filterBy option in vue-select, prevents
  // results from being filtered since they're filtered
  // based on scoring from the usda.gov website
  // https://vue-select.org/api/props.html#filterby

  export default {
    props: {
      value: { type: [Number, Object, String], default: null },
      src: { type: String, default: null },
      options: { type: Array, default: null },
      minChars: { type: Number, default: 1 },
      placeholder: { type: String, default: `Type to search...` },
      keysFromResponse: { type: String, default: null },
      showProp: { type: String, default: null },
      showPropFunction: { type: Function, default: null },
      disabled: { type: Boolean, default: false },
      includeSearchInOptions: { type: Boolean, default: false },
      // custom function which returns true to keep the option or false to discard it
      optionFilterFunction: { type: Function, default: null },
      // function takes search text and returns true if option matches search
      optionSearchFilter: {
        type: Function,
        default: (searchText, option) => searchText === option,
      },
    },

    watch: {
      options: {
        immediate: true,
        deep: true,
        handler(newValue) {
          if (newValue) this.transformOptions(newValue)
        },
      },
    },

    data() {
      return {
        mutableOptions: [],
        emptyResults: false,
        abortController: null,
      }
    },

    computed: {
      mutableValue: {
        get() {
          return this.value
        },

        set(upd) {
          this.$emit('input', upd.raw)
        },
      },
    },

    methods: {
      search: debounce(async function (searchText) {
        if (this.abortController && this.abortController.abort())
          this.abortController.abort()

        if (
          !searchText ||
          searchText.length === 0 ||
          searchText.length < this.minChars
        )
          return

        let matchedOptions = []
        if (this.includeSearchInOptions) {
          matchedOptions.push(searchText)
        }

        if (this.src) {
          this.abortController = new AbortController()
          const response = await R3sFetch(
            `${this.src}${
              this.src.includes('?') ? '&' : '?'
            }search=${searchText}`,
            { signal: this.abortController.signal }
          )
          const data = await handleFetchResponse(response)
          matchedOptions = [
            ...(this.keysFromResponse ? data[this.keysFromResponse] : [data]),
            ...matchedOptions,
          ]
        } else {
          matchedOptions = [
            ...this.options.filter((option) => {
              return this.optionSearchFilter(searchText, option)
            }),
            ...matchedOptions,
          ]
        }
        this.transformOptions(matchedOptions)
      }, 100),

      transformOptions(options) {
        const transformedOptions = (options || [])
          .filter((obj) => {
            return this.optionFilterFunction
              ? this.optionFilterFunction(obj)
              : true
          })
          .map((obj) => {
            return {
              label: this.getLabel(obj) || obj,
              raw: obj,
            }
          })
        this.mutableOptions = transformedOptions
        this.emptyResults = this.mutableOptions.length === 0
      },

      searchMessage(searching) {
        if (searching && !this.emptyResults) return 'Searching...'
        return this.emptyResults ? 'No items found' : 'Type to search...'
      },

      resetOptions() {
        this.transformOptions(this.options)
        this.emptyResults = false
      },

      getLabel(obj) {
        if (typeof this.showPropFunction === 'function')
          return this.showPropFunction(obj)

        if (this.showProp)
          return this.showProp.split('.').reduce((o, key) => o[key], obj)
        return obj
      },
    },
  }
</script>
<style lang="scss">
  .vs__search {
    font-size: 0.8rem !important;
  }
</style>
