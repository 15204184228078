<template lang="pug">
typeahead-input-new(
  v-model="mutableValue"
  :options="optionList"
  :placeholder="placeholder"
  :includeSearchInOptions="true"
  :optionSearchFilter="optionSearchFilter"
  :disabled="disabled"
)
  template(v-slot:option="{label: option}")
    div.d-flex.align-items-center.my-1(style="height: 40px")
      template
        //- Existing User
        user-avatar(
          v-if="!!option.id"
          :userId="option.id"
          :maxHeight="'40px'"
          :shouldShowClose="false"
        )
        //- Distribution Group
        div.user-avatar-wrapper(v-else-if="option.distribution_group_id") 
          div.user-avatar.rounded-circle.d-flex.align-items-center.justify-content-center(
            style="{border: 1px solid #ffffff; maxHeight: 40px; maxWidth: 40px;}"
          )
            i.fa-light.fa-users(style="font-size: medium;")

        //- Unknown email
        div.user-avatar-wrapper(v-else) 
          div.user-avatar.rounded-circle.d-flex.align-items-center.justify-content-center(
            style="{border: 1px solid #ffffff; maxHeight: 40px; maxWidth: 40px;}"
          )
            i.fa-light.fa-user-plus(style="font-size: medium;") 

      div.ml-3
        div.strong {{ option.name || option}}
        div {{  option.username_email }}
</template>

<script>
  import { mapState } from 'vuex'
  export const AssigneeType = Object.freeze({
    TEAM_USER: 'team_user',
    INTERNAL_USER: 'internal_user',
    DISTRIBUTION_GROUP: 'distribution_group',
    UPLOAD_PORTAL_USER: 'upload_portal_user',
    POLICY_PORTAL_USER: 'policy_portal_user',
    EMAIL: 'email',
  })

  export default {
    props: {
      value: { type: [Number, Object, String], default: null },
      placeholder: { type: String, default: `Add User...` },

      includeTeamUsers: { type: Boolean, default: true },
      includeInternalUsers: { type: Boolean, default: false },
      includeDistributionGroups: { type: Boolean, default: false },
      includePolicyPortalUsers: { type: Boolean, default: false },
      includeUploadPortalUsers: { type: Boolean, default: false },

      disabled: { type: Boolean, default: false },
    },

    data() {
      return {}
    },

    computed: {
      ...mapState({
        teamUsers: (state) => state.team.users,
        teamAndInternalUsers: (state) => state.team.teamAndInternalUsers,
        distributionGroups: (state) => state.team.distributionGroups,
        policyPortalUsers: (state) => state.team.policyPortalUsers,
        uploadPortalUsers: (state) => state.team.uploadPortalUsers,
      }),
      mutableValue: {
        get() {
          return this.value
        },

        set(newVal) {
          this.$emit(
            'input',
            newVal.type || AssigneeType.EMAIL,
            newVal.id || newVal.distribution_group_id || newVal
          )
        },
      },

      optionList() {
        let options = []
        if (this.includeTeamUsers) {
          for (let user of this.teamUsers) {
            if (options.map((o) => o.id).indexOf(user.id) === -1) {
              options.push({
                id: user.id,
                name: user.name,
                username_email: user.username_email,
                type: AssigneeType.TEAM_USER,
              })
            }
          }
        }
        if (this.includeInternalUsers)
          for (let user of this.teamAndInternalUsers) {
            if (options.map((o) => o.id).indexOf(user.id) === -1) {
              options.push({
                id: user.id,
                name: user.name,
                username_email: user.username_email,
                type: AssigneeType.INTERNAL_USER,
              })
            }
          }
        if (this.includeDistributionGroups)
          options = options.concat(
            this.distributionGroups.map((group) => ({
              distribution_group_id: group.id,
              name: group.name,
              username_email: null,
              type: AssigneeType.DISTRIBUTION_GROUP,
            }))
          )
        if (this.includePolicyPortalUsers) {
          for (let user of this.policyPortalUsers) {
            if (options.map((o) => o.id).indexOf(user.id) === -1) {
              options.push({
                id: user.id,
                name: user.name,
                username_email: user.username_email,
                type: AssigneeType.POLICY_PORTAL_USER,
              })
            }
          }
        }
        if (this.includeUploadPortalUsers) {
          for (let user of this.uploadPortalUsers) {
            if (options.map((o) => o.id).indexOf(user.id) === -1) {
              options.push({
                id: user.id,
                name: user.name,
                username_email: user.username_email,
                type: AssigneeType.UPLOAD_PORTAL_USER,
              })
            }
          }
        }

        return options
      },
    },

    methods: {
      optionSearchFilter(searchText, option) {
        return (
          option?.name?.toLowerCase().includes(searchText?.toLowerCase()) ||
          option?.username_email
            ?.toLowerCase()
            .includes(searchText?.toLowerCase())
        )
      },
    },

    mounted() {
      this.$store.dispatch('getTeamUsers')
      this.$store.dispatch('getTeamAndInternalUsers')
      this.$store.dispatch('getTeamUploadUsers')
      // this.$store.dispatch('team/getPolicyPortalUsers')
      this.$store.dispatch('getTeamDistributionGroups')
    },
  }
</script>
