import Vue from 'vue'

import {
  BDropdown,
  BDropdownItem,
  BFormTimepicker,
  BPagination,
  BPopover,
  BTooltip,
} from 'bootstrap-vue'
import Draggable from 'vuedraggable'
import vSelect from 'vue-select'

import AchievementModal from './AchievementModal'
import AddRemoveBtnGroup from './AddRemoveBtnGroup'
import AddTagToEntity from './AddTagToEntity'
import AddTeamMemberModal from './AddTeamMemberModal'
import AddUserToModuleModal from './AddUserToModuleModal'
import AssessmentControlFinderPopover from './AssessmentControlFinderPopover'
import AssessmentRequestDueDateUpdater from './AssessmentRequestDueDateUpdater'
import AssessmentRequestFinder from './AssessmentRequestFinder'
import AssessmentRequestFinderPopover from './AssessmentRequestFinderPopover'
import AssessmentRequestMapper from './AssessmentRequestMapper'
import AssigneeDropdown from './AssigneeDropdown.vue'
import AssigneeUpdates from './AssigneeUpdates'
import AttachmentItem from './AttachmentItem'
import AttestationModal from './AttestationModal'
import ExtensionList from './extension/ExtensionList'
import BlockchainTxnPopover from './BlockchainTxnPopover'
import BrandedCardFooter from './BrandedCardFooter'
import Breadcrumbs from './Breadcrumbs'
import BubbleNumericSelector from './BubbleNumericSelector'
import BulkUploadUsersModal from './BulkUploadUsersModal'
import Calendar from './Calendar'
import CalendarRecurringPicker from './CalendarRecurringPicker'
import CardPicLeft from './CardPicLeft'
import CardTabs from './CardTabs'
import ChangeLogTable from './ChangeLogTable'
import CheckboxFilter from './CheckboxFilter'
import CircularImage from './CircularImage'
import ClickToEdit from './ClickToEdit'
import CompareDiffModal from './CompareDiffModal'
import ColorPicker from './ColorPicker'
import CommentCard from './CommentCard'
import CommentList from './CommentList'
import Comments from './Comments'
import ConfirmationModal from './ConfirmationModal'
import ConfirmationPopover from './ConfirmationPopover'
import ContactAccountManager from './ContactAccountManager'
import CreateEditTagModal from './CreateEditTagModal'
import CreateEditEnumModal from './CreateEditEnumModal'
import CreateEntityCalendarEvent from './CreateEntityCalendarEvent'
import CreateExtensionMappingModal from './extension/CreateExtensionMappingModal'
import CreateExtensionModal from './extension/CreateExtensionModal'
import CreateKeyContactModal from './CreateKeyContactModal'
import CreateKeyContactTbody from './CreateKeyContactTbody'
import CreateProjectTaskModal from './CreateProjectTaskModal'
import CreateQuestionnaireModal from './CreateQuestionnaireModal'
import CreateRiskRegisterItemModal from './CreateRiskRegisterItemModal'
import CreateSelfAssessmentModal from './CreateSelfAssessmentModal'
import CreateSystemComponentModal from './CreateSystemComponentModal'
import CreateSystemComponentTbody from './CreateSystemComponentTbody'
import CredentialsCard from './CredentialsCard'
import CredentialsUpdateModal from './CredentialsUpdateModal'
import CustomSlider from './CustomSlider'
import CustomDetails from './CustomDetails'
import DashboardWidgets from './DashboardWidgets'
import DataCircle from './DataCircle'
import DataCircleGroup from './DataCircleGroup'
import DatepickerShards from './DatepickerShards'
import DropDownRouterLink from './DropDownRouterLink'
import EntityCommentsList from './EntityCommentsList'
import EntityFilesList from './EntityFilesList'
import EntityTagsList from './EntityTagsList'
import ExpressionBuilder from './ExpressionBuilder'
import ExtensionExecutionHistoryModal from './extension/ExtensionExecutionHistoryModal'
import FeatureAvailableWrapper from './FeatureAvailableWrapper'
import FileUpdateModal from './FileUpdateModal'
import FileUploader from './FileUploader'
import Files from './Files'
import FrameworkProgressCharts from './FrameworkProgressCharts'
import GenericEditableCardTable from './GenericEditableCardTable'
import IconWithBadge from './IconWithBadge'
import ImgViewer from './ImgViewer'
import InputDebounce from './InputDebounce'
import KeyContacts from './KeyContacts'
import KeyContactsTable from './KeyContactsTable'
import JiraIntegrationModal from './JiraIntegrationModal'
import JiraTicketReviewPopover from './JiraTicketReviewPopover'
import PageHeader from './PageHeader'
import Pagination from './Pagination'
import PeriodicityUpdater from './PeriodicityUpdater'
import ProgressStepper from './progress-stepper/ProgressStepper'
import HeaderSubNav from './HeaderSubNav'
import Loader from './Loader'
import LoaderInline from './LoaderInline'
import MainPageHeader from './MainPageHeader'
import ManageJiraSyncModal from './JiraModals/ManageJiraSyncModal'
import MappingWithCheckboxes from './MappingWithCheckboxes'
import MfaModal from './MfaModal'
import NodeConfigCard from './NodeConfigCard'
import OauthButton from './OauthButton'
import OrgFileManager from './OrgFileManager'
import OrgFileManagerModal from './OrgFileManagerModal'
import OrgIndependenceCheck from './OrgIndependenceCheck'
import OrgIndependencePopover from './OrgIndependencePopover'
import OrgProfile from './OrgProfile'
import OrgSelectionModal from './OrgSelectionModal'
import PageError from './PageError'
import PdfViewer from './PdfViewer'
import PillInline from './PillInline'
import PillStatusUpdater from './PillStatusUpdater'
import PmTimeline from './pm-timeline/Timeline'
import PrettifyArrayOfObjects from './PrettifyArrayOfObjects'
import PreviewAttachmentModal from './PreviewAttachmentModal'
import UpgradeContainer from './UpgradeContainer'
import PreviewObject from './PreviewObject'
import ProgressBar from './ProgressBar'
import ProjectsOwnerGroup from './ProjectsOwnerGroup'
import ProjectsTaskOwnerGroup from './ProjectsTaskOwnerGroup'
import QuestionEditor from './QuestionEditor'
import QuestionnaireBuilder from './QuestionnaireBuilder'
import QuestionnaireReviewersGroup from './QuestionnaireReviewersGroup'
import QuestionnaireSectionEditorPopover from './QuestionnaireSectionEditorPopover'
import QuestionnaireSectionProgressBar from './QuestionnaireSectionProgressBar'
import QuestionnaireSelectionModal from './QuestionnaireSelectionModal'
import QuestionnaireTemplateToggle from './QuestionnaireTemplateToggle'
import R3sVendor from './R3sVendor'
import ReportCreateEditModal from './reports/ReportCreateEditModal'
import RichTextEditor from './RichTextEditor'
import RichTextEditorDebounce from './RichTextEditorDebounce'
import RiskFinderPopover from './RiskFinderPopover'
import RiskRegisterDataCircle from './RiskRegisterDataCircle'
import RiskRegisterFormalFindingsModal from './RiskRegisterFormalFindingsModal'
import RiskRegisterInitialRisk from './RiskRegisterInitialRisk'
import RiskRegisterAcceptableRisk from './RiskRegisterAcceptableRisk'
import RiskRegisterResidualRisk from './RiskRegisterResidualRisk'
import RiskRegisterItemStatusUpdater from './RiskRegisterItemStatusUpdater'
import RiskRegisterItemDecisionUpdater from './RiskRegisterItemDecisionUpdater'
import RiskRegisterOwnerGroup from './RiskRegisterOwnerGroup'
import RiskRegisterProgressBar from './RiskRegisterProgressBar'
import SchedulerForm from './SchedulerForm'
import ScoreCircle from './ScoreCircle'
import ScreenshotById from './ScreenshotById'
import SearchInput from './SearchInput'
import SecretInput from './SecretInput'
import SelectDropdownFilter from './SelectDropdownFilter'
import SelectButtons from './SelectButtons'
import SetupJiraSyncModal from './JiraModals/SetupJiraSyncModal'
import SlackChannels from './SlackChannels'
import Spreadsheet from './spreadsheet/Spreadsheet'
import SystemComponents from './SystemComponents'
import SystemComponentsTable from './SystemComponentsTable'
import TableColumnEditor from './TableColumnEditor'
import TypeaheadInput from './TypeaheadInput'
import TypeaheadInputNew from './TypeaheadInputNew'
import SearchVulnerabilitiesByField from './SearchVulnerabilitiesByField'
import UpdateItemFieldReasonModal from './UpdateItemFieldReasonModal'
import UpgradeSubscriptionModal from './UpgradeSubscriptionModal'
import UploadUserList from './UploadUserList'
import UploadUserModal from './UploadUserModal'
import UserAvatar from './UserAvatar'
import UserAvatarEditableGroup from './UserAvatarEditableGroup'
import UserAvatarGroup from './UserAvatarGroup'
import UserList from './UserList'
import UserPublicProfile from './UserPublicProfile'
import UserRoleTooltip from './UserRoleTooltip'
import VendorsOwnerGroup from './VendorsOwnerGroup'
import VulnerabilityCreateScanModal from './vulnerability/VulnerabilityCreateScanModal'
import VulnerabilityUpdateItemAttrToggle from './VulnerabilityUpdateItemAttrToggle'

import ChartjsBarChart from './chartjs/Bar'
import ChartjsBubbleChart from './chartjs/Bubble'
import ChartjsDoughnutChart from './chartjs/Doughnut'
import ChartjsHorizontalBarChart from './chartjs/HorizontalBar'
import ChartjsLineChart from './chartjs/Line'

// Reusable Assessment Components
import AssessmentStatusCircle from '../assessments/AssessmentStatusCircle'
import RequestStatusUpdater from '../assessments/RequestStatusUpdater'

Vue.component('AchievementModal', AchievementModal)
Vue.component('AddRemoveBtnGroup', AddRemoveBtnGroup)
Vue.component('AddTagToEntity', AddTagToEntity)
Vue.component('AddTeamMemberModal', AddTeamMemberModal)
Vue.component('AddUserToModuleModal', AddUserToModuleModal)
Vue.component('AssessmentControlFinderPopover', AssessmentControlFinderPopover)
Vue.component(
  'AssessmentRequestDueDateUpdater',
  AssessmentRequestDueDateUpdater
)
Vue.component('AssessmentRequestFinder', AssessmentRequestFinder)
Vue.component('AssessmentRequestFinderPopover', AssessmentRequestFinderPopover)
Vue.component('AssessmentRequestMapper', AssessmentRequestMapper)
Vue.component('AssigneeDropdown', AssigneeDropdown)
Vue.component('AssigneeUpdates', AssigneeUpdates)
Vue.component('AttachmentItem', AttachmentItem)
Vue.component('AttestationModal', AttestationModal)
Vue.component('ExtensionList', ExtensionList)
Vue.component('BDropdown', BDropdown)
Vue.component('BDropdownItem', BDropdownItem)
Vue.component('BFormTimepicker', BFormTimepicker)
Vue.component('BPagination', BPagination)
Vue.component('BPopover', BPopover)
Vue.component('BTooltip', BTooltip)
Vue.component('BlockchainTxnPopover', BlockchainTxnPopover)
Vue.component('BrandedCardFooter', BrandedCardFooter)
Vue.component('Calendar', Calendar)
Vue.component('Breadcrumbs', Breadcrumbs)
Vue.component('BubbleNumericSelector', BubbleNumericSelector)
Vue.component('BulkUploadUsersModal', BulkUploadUsersModal)
Vue.component('CalendarRecurringPicker', CalendarRecurringPicker)
Vue.component('CardPicLeft', CardPicLeft)
Vue.component('CardTabs', CardTabs)
Vue.component('ChangeLogTable', ChangeLogTable)
Vue.component('ChartjsBarChart', ChartjsBarChart)
Vue.component('ChartjsBubbleChart', ChartjsBubbleChart)
Vue.component('ChartjsDoughnutChart', ChartjsDoughnutChart)
Vue.component('ChartjsHorizontalBarChart', ChartjsHorizontalBarChart)
Vue.component('ChartjsLineChart', ChartjsLineChart)
Vue.component('CheckboxFilter', CheckboxFilter)
Vue.component('CircularImage', CircularImage)
Vue.component('ClickToEdit', ClickToEdit)
Vue.component('CompareDiffModal', CompareDiffModal)
Vue.component('ColorPicker', ColorPicker)
Vue.component('CommentCard', CommentCard)
Vue.component('CommentList', CommentList)
Vue.component('Comments', Comments)
Vue.component('ConfirmationModal', ConfirmationModal)
Vue.component('ConfirmationPopover', ConfirmationPopover)
Vue.component('ContactAccountManager', ContactAccountManager)
Vue.component('CreateEditEnumModal', CreateEditEnumModal)
Vue.component('CreateEditTagModal', CreateEditTagModal)
Vue.component('CreateEntityCalendarEvent', CreateEntityCalendarEvent)
Vue.component('CreateExtensionMappingModal', CreateExtensionMappingModal)
Vue.component('CreateExtensionModal', CreateExtensionModal)
Vue.component('CreateKeyContactModal', CreateKeyContactModal)
Vue.component('CreateKeyContactTbody', CreateKeyContactTbody)
Vue.component('CreateProjectTaskModal', CreateProjectTaskModal)
Vue.component('CreateQuestionnaireModal', CreateQuestionnaireModal)
Vue.component('CreateRiskRegisterItemModal', CreateRiskRegisterItemModal)
Vue.component('CreateSelfAssessmentModal', CreateSelfAssessmentModal)
Vue.component('CreateSystemComponentModal', CreateSystemComponentModal)
Vue.component('CreateSystemComponentTbody', CreateSystemComponentTbody)
Vue.component('CredentialsCard', CredentialsCard)
Vue.component('CredentialsUpdateModal', CredentialsUpdateModal)
Vue.component('CustomSlider', CustomSlider)
Vue.component('CustomDetails', CustomDetails)
Vue.component('DashboardWidgets', DashboardWidgets)
Vue.component('DataCircle', DataCircle)
Vue.component('DataCircleGroup', DataCircleGroup)
Vue.component('DatepickerShards', DatepickerShards)
Vue.component('Draggable', Draggable)
Vue.component('DropDownRouterLink', DropDownRouterLink)
Vue.component('EntityCommentsList', EntityCommentsList)
Vue.component('EntityFilesList', EntityFilesList)
Vue.component('EntityTagsList', EntityTagsList)
Vue.component('ExpressionBuilder', ExpressionBuilder)
Vue.component('ExtensionExecutionHistoryModal', ExtensionExecutionHistoryModal)
Vue.component('FeatureAvailableWrapper', FeatureAvailableWrapper)
Vue.component('FileUpdateModal', FileUpdateModal)
Vue.component('FileUploader', FileUploader)
Vue.component('Files', Files)
Vue.component('FrameworkProgressCharts', FrameworkProgressCharts)
Vue.component('GenericEditableCardTable', GenericEditableCardTable)
Vue.component('HeaderSubNav', HeaderSubNav)
Vue.component('IconWithBadge', IconWithBadge)
Vue.component('ImgViewer', ImgViewer)
Vue.component('InputDebounce', InputDebounce)
Vue.component('KeyContacts', KeyContacts)
Vue.component('KeyContactsTable', KeyContactsTable)
Vue.component('JiraIntegrationModal', JiraIntegrationModal)
Vue.component('JiraTicketReviewPopover', JiraTicketReviewPopover)
Vue.component('Loader', Loader)
Vue.component('LoaderInline', LoaderInline)
Vue.component('MainPageHeader', MainPageHeader)
Vue.component('ManageJiraSyncModal', ManageJiraSyncModal)
Vue.component('MappingWithCheckboxes', MappingWithCheckboxes)
Vue.component('MfaModal', MfaModal)
Vue.component('NodeConfigCard', NodeConfigCard)
Vue.component('OauthButton', OauthButton)
Vue.component('OrgFileManager', OrgFileManager)
Vue.component('OrgFileManagerModal', OrgFileManagerModal)
Vue.component('OrgIndependenceCheck', OrgIndependenceCheck)
Vue.component('OrgIndependencePopover', OrgIndependencePopover)
Vue.component('OrgProfile', OrgProfile)
Vue.component('OrgSelectionModal', OrgSelectionModal)
Vue.component('PageError', PageError)
Vue.component('PageHeader', PageHeader)
Vue.component('Pagination', Pagination)
Vue.component('PeriodicityUpdater', PeriodicityUpdater)
Vue.component('PdfViewer', PdfViewer)
Vue.component('PillInline', PillInline)
Vue.component('PillStatusUpdater', PillStatusUpdater)
Vue.component('PmTimeline', PmTimeline)
Vue.component('PrettifyArrayOfObjects', PrettifyArrayOfObjects)
Vue.component('ProjectsOwnerGroup', ProjectsOwnerGroup)
Vue.component('ProjectsTaskOwnerGroup', ProjectsTaskOwnerGroup)
Vue.component('UpgradeContainer', UpgradeContainer)
Vue.component('PreviewAttachmentModal', PreviewAttachmentModal)
Vue.component('PreviewObject', PreviewObject)
Vue.component('ProgressBar', ProgressBar)
Vue.component('ProgressStepper', ProgressStepper)
Vue.component('QuestionEditor', QuestionEditor)
Vue.component('QuestionnaireBuilder', QuestionnaireBuilder)
Vue.component('QuestionnaireReviewersGroup', QuestionnaireReviewersGroup)
Vue.component(
  'QuestionnaireSectionEditorPopover',
  QuestionnaireSectionEditorPopover
)
Vue.component(
  'QuestionnaireSectionProgressBar',
  QuestionnaireSectionProgressBar
)
Vue.component('QuestionnaireSelectionModal', QuestionnaireSelectionModal)
Vue.component('QuestionnaireTemplateToggle', QuestionnaireTemplateToggle)
Vue.component('R3sVendor', R3sVendor)
Vue.component('ReportCreateEditModal', ReportCreateEditModal)
Vue.component('RichTextEditor', RichTextEditor)
Vue.component('RichTextEditorDebounce', RichTextEditorDebounce)
Vue.component('RiskFinderPopover', RiskFinderPopover)
Vue.component('RiskRegisterDataCircle', RiskRegisterDataCircle)
Vue.component(
  'RiskRegisterFormalFindingsModal',
  RiskRegisterFormalFindingsModal
)
Vue.component('RiskRegisterItemStatusUpdater', RiskRegisterItemStatusUpdater)
Vue.component(
  'RiskRegisterItemDecisionUpdater',
  RiskRegisterItemDecisionUpdater
)
Vue.component('RiskRegisterInitialRisk', RiskRegisterInitialRisk)
Vue.component('RiskRegisterAcceptableRisk', RiskRegisterAcceptableRisk)
Vue.component('RiskRegisterResidualRisk', RiskRegisterResidualRisk)
Vue.component('RiskRegisterOwnerGroup', RiskRegisterOwnerGroup)
Vue.component('RiskRegisterProgressBar', RiskRegisterProgressBar)
Vue.component('SchedulerForm', SchedulerForm)
Vue.component('ScoreCircle', ScoreCircle)
Vue.component('ScreenshotById', ScreenshotById)
Vue.component('SearchInput', SearchInput)
Vue.component('SecretInput', SecretInput)
Vue.component('SelectDropdownFilter', SelectDropdownFilter)
Vue.component('SelectButtons', SelectButtons)
Vue.component('SetupJiraSyncModal', SetupJiraSyncModal)
Vue.component('SlackChannels', SlackChannels)
Vue.component('Spreadsheet', Spreadsheet)
Vue.component('SystemComponents', SystemComponents)
Vue.component('SystemComponentsTable', SystemComponentsTable)
Vue.component('TableColumnEditor', TableColumnEditor)
Vue.component('TypeaheadInput', TypeaheadInput) // NOTE: should be used when requires backend query
Vue.component('TypeaheadInputNew', TypeaheadInputNew)
Vue.component('SearchVulnerabilitiesByField', SearchVulnerabilitiesByField)
Vue.component('UserAvatar', UserAvatar)
Vue.component('UpdateItemFieldReasonModal', UpdateItemFieldReasonModal)
Vue.component('UpgradeSubscriptionModal', UpgradeSubscriptionModal)
Vue.component('UploadUserList', UploadUserList)
Vue.component('UploadUserModal', UploadUserModal)
Vue.component('UserAvatarEditableGroup', UserAvatarEditableGroup)
Vue.component('UserAvatarGroup', UserAvatarGroup)
Vue.component('UserPublicProfile', UserPublicProfile)
Vue.component('UserRoleTooltip', UserRoleTooltip)
Vue.component('UserList', UserList)
Vue.component('VSelect', vSelect)
Vue.component('VendorsOwnerGroup', VendorsOwnerGroup)
Vue.component('VulnerabilityCreateScanModal', VulnerabilityCreateScanModal)
Vue.component(
  'VulnerabilityUpdateItemAttrToggle',
  VulnerabilityUpdateItemAttrToggle
)

Vue.component('AssessmentStatusCircle', AssessmentStatusCircle)
Vue.component('RequestStatusUpdater', RequestStatusUpdater)
