<template lang="pug">
  div.form-group
    div.d-flex.align-items-center.justify-content-between
      button.btn.btn-primary(
        v-if="allowRecordCreation"
        :disabled="isCreatingRow"
        @click="addCreationRow") + New {{childEntity}}
      label.h6.my-0(v-else) {{childEntity}}:
      div.custom-control.custom-toggle-sm.custom-toggle
        input.custom-control-input(
          type="checkbox",
          :id="`mapped-items-toggle-${_uid}`",
          name="mapped-items-toggle",
          v-model="onlyShowMappedItems")
        label.custom-control-label(:for="`mapped-items-toggle-${_uid}`") {{mappedVerbiageOverride ? mappedVerbiageOverride : `Only Show Mapped ${childEntity}`}}
      search-input.pb-1(v-model="searchText" placeholder="Filter...")
    table.table
      thead
        tr
          th.col
          th.col(v-for="field in childFieldsToDisplay") 
            div.d-flex.align-items-center.clickable(v-if="allowSorting && !!field.sortable" @click="setSorting(field.fieldName)")
              div.mr-1 {{field.fieldDisplay}}
              i.fa(:class="getSorting(field.fieldName)")
            div(v-else) {{field.fieldDisplay}}
      tbody(v-if="paginatedItems.data.length > 0 || (allowRecordCreation && isCreatingRow)")
        tr(v-if="allowRecordCreation && isCreatingRow")
          td(scope="row")
            div.d-flex
              i.fa.fa-check.text-success.clickable.mr-2(@click="createRow")
              i.fa.fa-times.text-danger.clickable(@click="clearCreationRow")
          td(v-for="field in recordCreationFields")
            input.form-control(:id="`${field}-${_uid}`", v-model="creationRow[field]")
        tr(v-for="item in paginatedItems.data" :key="item.id")
          th(scope="row")
            div.custom-control.custom-checkbox
              input.custom-control-input(
                :id="`item-${item.id}-${_uid}`",
                type="checkbox",
                :checked="isSelected(item.id)"
                @change="selectItem(item.id)")
              label.custom-control-label(:for="`item-${item.id}-${_uid}`")
          td(v-for="field in childFieldsToDisplay" v-html="item[field.fieldName]")
        tr
          td(colspan="100%")
            div.d-flex.justify-content-center
              pagination(
                :info="paginatedItems",
                @changePage="p => itemsPage = p",
                @changePerPage="p => { itemsPage = 1; itemsPerPage = p }")
      tbody(v-else-if="onlyShowMappedItems")
        tr.text-center
          td.text-muted(colspan="100%") No {{childEntity}} Mapped
      tbody(v-else)
        tr.text-center
          td.text-muted(colspan="100%") No {{childEntity}} Available to Map
</template>

<script>
  import Utilities from '@risk3sixty/utilities'

  export default {
    props: {
      value: { type: Set, default: () => new Set() },
      childEntity: { type: String, required: true },
      childFieldsToDisplay: {
        type: Array,
        default: () => [{ fieldName: 'id', fieldDisplay: 'ID' }],
      },
      availableItems: { type: Array, default: () => [] }, //Array of objects. Must at least have an 'id' field
      pageSize: { type: Number, default: 5 },
      mappedVerbiageOverride: { type: String, default: null },

      // currently only supports text fields
      allowRecordCreation: { type: Boolean, default: false },
      recordCreationFields: { type: Array, default: () => [] },

      // only allows sorting on fields that have `sortable` set to true
      allowSorting: { type: Boolean, default: false },
      // must be a sortable field
      // defaultSortBy: { type: String, default: '' },
    },

    watch: {
      value: {
        handler: function (newVal) {
          this.selectedItems = new Set(newVal)
        },
        immediate: true,
      },

      // defaultSortBy: {
      //   handler: function (newVal) {
      //     this.sortBy = newVal
      //   },
      //   immediate: true,
      // },

      onlyShowMappedItems() {
        this.itemsPage = 1
      },
    },

    data() {
      return {
        selectedItems: new Set(),
        onlyShowMappedItems: false,
        searchText: '',
        itemsPerPage: this.pageSize,
        itemsPage: 1,
        isCreatingRow: false,
        creationRow: {},
        sortBy: '',
        sortDirection: 'asc',
      }
    },

    computed: {
      paginatedItems() {
        let itemsToDisplay
        if (this.onlyShowMappedItems) {
          itemsToDisplay = this.availableItems.filter(
            (item) =>
              this.selectedItems.has(item.id) && this.itemIncludedInSearch(item)
          )
        } else {
          itemsToDisplay = this.availableItems.filter((item) =>
            this.itemIncludedInSearch(item)
          )
        }

        if (this.sortBy) {
          itemsToDisplay = itemsToDisplay.sort((a, b) => {
            // localeCompare only works on strings
            if (typeof a[this.sortBy] != 'string')
              return this.sortDirection === 'asc' ? 1 : -1
            const order = a[this.sortBy].localeCompare(b[this.sortBy], {
              numeric: true,
            })

            return this.sortDirection === 'asc' ? order : -order
          })
        }

        const paginationData = Utilities.Arrays.paginateArray(
          itemsToDisplay,
          this.itemsPerPage,
          this.itemsPage
        )
        return {
          data: paginationData.data,
          currentPage: paginationData.currentPage,
          totalCount: paginationData.dataLength,
          numberPages: paginationData.numberOfPages,
          perPage: this.itemsPerPage,
        }
      },
    },

    methods: {
      selectItem(itemId) {
        this.selectedItems.has(itemId)
          ? this.selectedItems.delete(itemId)
          : this.selectedItems.add(itemId)
        this.$emit('input', this.selectedItems)
      },

      itemIncludedInSearch(item) {
        if (!this.searchText) return true
        return this.childFieldsToDisplay.some((childField) => {
          if (!item[childField.fieldName]) return false
          return item[childField.fieldName]
            .toLowerCase()
            .includes(this.searchText.toLowerCase())
        })
      },

      isSelected(id) {
        return this.selectedItems.has(id)
      },

      addCreationRow() {
        // create empty object of fields to display
        const newRow = this.childFieldsToDisplay.reduce((acc, field) => {
          acc[field.fieldName] = ''
          return acc
        }, {})

        this.creationRow = newRow
        this.isCreatingRow = true
        this.$emit('creating')
      },

      createRow() {
        try {
          this.$emit('create', this.creationRow)
          this.clearCreationRow()
        } catch (error) {
          window.toastr.error(`Failed to create ${this.childEntity}. ${error}`)
        }
      },

      clearCreationRow() {
        this.creationRow = {}
        this.isCreatingRow = false
        this.$emit('created')
      },

      getSorting(key) {
        if (this.sortBy == key) {
          return this.sortDirection === 'asc' ? 'fa-sort-up' : 'fa-sort-down'
        } else {
          return 'fa-sort'
        }
      },

      setSorting(key) {
        if (this.sortBy === key) {
          this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc'
        } else {
          this.sortBy = key
          this.sortDirection = 'asc'
        }
      },
    },

    mounted() {
      this.onlyShowMappedItems = false
    },
  }
</script>
<style lang="scss" scoped>
  .col {
    width: unset;
  }
</style>
