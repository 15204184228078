<template lang="pug">
div.mention-list
  div.p-2(v-if="items.length === 0") #[i No items]
  div.clickable.list-hover(
    v-else
    v-for="(item, index) in items"
    :id="`b-item-${index}`"
    :key="index"
    :class="{ 'is-selected': index === selectedIndex }"
    @click="selectItem(index)") 
    div.px-2 {{ item.value }}
</template>

<script>
  export default {
    props: {
      items: {
        type: Array,
        required: true,
      },

      command: {
        type: Function,
        required: true,
      },
    },

    watch: {
      items() {
        this.selectedIndex = 0
      },
    },

    data() {
      return {
        selectedIndex: 0,
      }
    },

    methods: {
      onKeyDown({ event }) {
        if (event.key === 'ArrowUp') {
          this.upHandler()
          return true
        }

        if (event.key === 'ArrowDown') {
          this.downHandler()
          return true
        }

        if (event.key === 'Enter') {
          this.enterHandler()
          return true
        }

        return false
      },

      upHandler() {
        this.selectedIndex =
          (this.selectedIndex + this.items.length - 1) % this.items.length
      },

      downHandler() {
        this.selectedIndex = (this.selectedIndex + 1) % this.items.length
      },

      enterHandler() {
        this.selectItem(this.selectedIndex)
      },

      selectItem(index) {
        const item = this.items[index]
        this.command({ id: item.value })
        this.$parent.$emit('mentionReplaced', item)
      },
    },
  }
</script>
<style lang="scss" scoped>
  // when .list-hover is being hovered change the background to background: #d3e1eb;
  .list-hover {
    padding: 0.5rem 0;
    &:hover {
      background: #d3e1eb;
    }
  }
</style>
